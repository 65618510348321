<template>
    <v-container style="margin-bottom: 50px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                        <div>
                            <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-1" v-show="display === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="rounded-l" outlined>
                    <v-card-text class="p-2">
                        <v-container>
                            <v-row>
                                <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <h6 class="blue-lcd mr-5 mt-3">カルテ番号</h6>
                                        <v-text-field
                                            solo
                                            label="カルテ番号"
                                            persistent-hint
                                            required
                                            v-model="card_number"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </div>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="5" lg="5" class="pt-0 pb-0">
                                    <div class="d-flex">
                                        <h6 class="blue-lcd mr-5 mt-3">Free Text</h6>
                                        <v-text-field
                                            solo
                                            label="Free Text"
                                            persistent-hint
                                            required
                                            v-model="free_text"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </div>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3" class="ma-0 pa-0">
                                    <v-btn class="border-12 text-white p-4 mr-3" color="secondary" @click="getCardNumber(1,10)">{{ $t('labels.search') }}</v-btn>
                                    <v-btn class="border-12 p-4" color="#fff" @click="clearFilter()">{{ $t('labels.clear') }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #e83e8c;">
                    <v-card-title>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-data-table
                            :headers="headers"
                            :items="cards"
                            :options.sync="options"
                            :loading="loadingDatatable"
                            loading-text="Please wait, retrieving data"
                            :server-items-length="totalItems"
                            :page.sync="pagination"
                            page-count="10"
                            class="elevation-1"
                            height="450"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :item-class="tr_datatable"
                            @click:row="showDetail"
                        >
                            <template v-slot:[`header.patient_id`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.name`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.price`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.accounted_date`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.created_at`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`header.updated_at`]="{ header }">
                                <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                    <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                    <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                            </template>
                            <template v-slot:[`item.paid`]="{ item }">
                                <v-chip
                                    small
                                    class="pl-5 pr-5"
                                    color="green"
                                    text-color="white"
                                    v-if="item.paid === 1"
                                >
                                支払済
                                </v-chip>
                                <v-chip
                                    small
                                    class="pl-5 pr-5"
                                    color="red"
                                    text-color="white"
                                    v-else
                                >
                                未払い
                                </v-chip>
                            </template> 
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-1" v-show="displayItem === 'block' ? true : false">
            <v-col cols="12">
                <v-card class="elevation-6 rounded-l" style="border-top: 3px solid #e83e8c;">
                    <v-card-title class="p-1">                        
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="close()"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="5" class="pt-0 pb-0">
                                <v-card class="rounded-l" outlined>
                                    <v-card-text>
                                        <h6>
                                            <b>{{ name }}</b> | {{ ruby }}
                                        </h6>
                                        <p class="mb-0 pb-0">  {{date_of_birth }}  <b>|</b> {{age}} <b>|</b> {{gender}} </p>
                                        <v-list dense>
                                            <v-list-item class="pl-0">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-home</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{address}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="pl-0">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-phone</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>{{mobile}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-card 
                                                height="635"
                                                outlined
                                                >
                                                    <v-card-text class="p-1">
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col cols="12" class="p-1">
                                                                    <v-card outlined class="border-12 pl-3 pr-3 pt-2 pb-0 mt-3" v-for="(detail, i) in accountans" :key="i">
                                                                        <v-row class="mt-1" v-if="detail.course">
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pink-heading pb-0 mb-0 mr-2">{{$t('labels.subscriptionCourses')}} : </p>
                                                                            </v-col>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p>{{ detail ? detail.course : '-' }}</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="mt-1" v-if="detail.single">
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pink-heading pb-0 mb-0 mr-2">単品 : </p>
                                                                            </v-col>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pb-0 mb-0">{{ detail ? detail.single : '-' }}</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="mt-1" v-if="detail.medicines.length > 0">
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pink-heading pb-0 mb-0">{{$t('labels.medicine')}} : </p>
                                                                            </v-col>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">

                                                                                <v-list dense class="pl-0 pr-0 pt-0 pb-0">
                                                                                    <v-list-item-group
                                                                                        color="primary"
                                                                                    >
                                                                                        <v-list-item
                                                                                        v-for="(medicine, i) in detail.medicines" :key="i"
                                                                                        class="pt-0 pb-0 mb-0 mt-0 pl-0 pr-0"
                                                                                        >
                                                                                        <v-list-item-content>
                                                                                            <v-list-item-title>
                                                                                                <div class="d-flex">
                                                                                                    <p v-if="medicine.date" class="mr-1 pb-0 mb-0">{{medicine.date}}</p>
                                                                                                    <p class="mr-1 pb-0 mb-0">{{medicine.name}}</p>
                                                                                                    <p class="mr-1 pb-0 mb-0">{{medicine.amount}}</p>
                                                                                                    <p class="pb-0">{{medicine.unit}}</p>
                                                                                                </div>
                                                                                            </v-list-item-title>
                                                                                                
                                                                                        </v-list-item-content>
                                                                                        </v-list-item>
                                                                                    </v-list-item-group>
                                                                                </v-list>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row class="mb-1" v-if="detail.items.length > 0">
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pink-heading pb-0 mb-0">{{$t('labels.item')}} : </p>
                                                                            </v-col>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12" v-if="detail">
                                                                                <v-list dense class="pt-0 pb-0">
                                                                                    <v-list-item-group
                                                                                        color="primary"
                                                                                    >
                                                                                        <v-list-item
                                                                                        v-for="(item, i) in detail.items" :key="i"
                                                                                        class="pt-0 pb-0 mb-0 mt-0 pl-0 pr-0"
                                                                                        >
                                                                                        <v-list-item-content>
                                                                                            <v-list-item-title>
                                                                                                <div class="d-flex">
                                                                                                    <p v-if="item.date" class="mr-1 mb-0 pb-0">{{item.date}}</p>
                                                                                                    <p class="mr-1 mb-0 pb-0">{{item.name}}</p>
                                                                                                    <p class="mr-1 mb-0 pb-0">{{item.amount}}</p>
                                                                                                    <p class="mr-1 mb-0 pb-0">{{item.unit}}</p>
                                                                                                </div>
                                                                                            </v-list-item-title>
                                                                                        </v-list-item-content>
                                                                                        </v-list-item>
                                                                                    </v-list-item-group>
                                                                                </v-list>
                                                                            </v-col>
                                                                        </v-row> 
                                                                        <v-row>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p class="pink-heading pb-0 mb-0" style="font-size:0.9rem">{{$t('labels.price')}} : </p>
                                                                            </v-col>
                                                                            <v-col xs="12" sm="12" md="12" class="pb-0 pt-0 col-12">
                                                                                <p>{{ detail ? $store.getters.convertToCurrencyUs(detail.price) : '-' }} 円</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="7" class="pt-0 pb-0">
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                            <v-card class="rounded-l" outlined height="815">
                                                <v-card-text>
                                                    <div class="d-flex">
                                                        <v-btn-toggle
                                                            v-model="toggle_multiple"
                                                            dense
                                                            multiple
                                                        >
                                                            <v-btn>
                                                                <v-icon>mdi-application</v-icon>
                                                                請求書発行
                                                            </v-btn>
                                                            <v-btn>
                                                                <v-icon>mdi-receipt-text-outline</v-icon>
                                                                領収書発行
                                                            </v-btn>
                                                            <v-btn>
                                                                <v-icon>mdi-sitemap</v-icon>
                                                                明細書発行
                                                            </v-btn>
                                                            
                                                        </v-btn-toggle>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { Icon } from '@iconify/vue2';
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {backendAdmin} from "../../backend-api/backendAdmin"

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_}',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        Icon,
        ValidationProvider,
        ValidationObserver,
        VueCropper
    },
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'ホーム',
                disabled: false,
                href: '/staff/dashboard',
                },
                {
                text: '会計',
                disabled: true,
                href: 'breadcrumbs_link_2',
                }
            ],
            show: false,
            cards: [],
            loading: false,
            firstLoad: true,
            theme: {
                isDark: false
            },
            items: [{name: 'a'}, {name: 'b'}, {name: 'c'}, {name: 'd'}],
            searchItem: '',
            loadingDatatable: false,
            headers:[
                { text: 'カルテ番号', value: 'patient_id', sortable: false},
                { text: '名前', value: 'name', sortable: false },
                { text: '決済状態', value: 'paid', sortable: false },
                { text: '金額', value: 'price', sortable: false },
                { text: '計上日', value: 'accounted_date', sortable: false },
                { text: '作成日時', value: 'created_at', sortable: false },
                { text: '更新日時', value: 'updated_at', sortable: false }
            ], 
            cards: [],
            detailItem: null,
            display: 'block',
            displayItem: 'none',
            card_number: '',
            free_text: '',
            options: {},
            totalItems: 10,
            pagination: 1,
            sortBy: 'desc',
            sortName: 'patient_id',
            date_of_birth: '',
            name: '',
            ruby: '',
            age: '',
            gender: '',
            mobile: '',
            address: '',
            accountans: [],
            toggle_multiple: [],
        }
    },
    mounted() {
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        clearFilter(){
            this.card_number = ''
            this.free_text = ''
        },
        close(){
            this.display = 'block'
            this.displayItem = 'none'
            this.getCardNumber(this.pagination,10)
        },
        async getCardNumber(page = 1, itemsPerPage = 10){
            this.loadingDatatable = true
            this.pagination = page
            this.cards = []

            var reqBody = {
                'patient_id': this.card_number ? parseInt(this.card_number) : -1,
                'free_text': this.free_text ? this.free_text : '',
                'sort_key': this.sortName,
                'order' : this.sortBy,
                'page': page,
                'limit': itemsPerPage
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/accountants`, reqBody, false, false, false)

            if (respData.data.error_message === "") {
                this.loadingDatatable = false
                this.totalItems = respData.data.extra
                this.cards = respData.data.value
            }
            
        },
        async showDetail(row){
            console.log(row);
            this.detailItem = row
            this.display = 'none'
            this.displayItem = 'block'
            this.getPatientProfile()
            this.getEachAccountant()
        },
        async getPatientProfile(){
            var reqBody = {
                'id': this.detailItem ? parseInt(this.detailItem.patient_id) : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/profile`, reqBody, false, false, false)

            this.date_of_birth = respData.data.value.date_of_birth
            this.name = respData.data.value.name
            this.ruby = respData.data.value.rubi
            this.age = respData.data.value.age + '歳'
            this.gender = respData.data.value.gender
            this.mobile = respData.data.value.mobile
            this.address = respData.data.value.prefecture + ' ' + respData.data.value.city + ' ' + respData.data.value.street + ' ' + respData.data.value.building
            // this.history_lists = respData.data.value.history_list
        },
        async getEachAccountant(){
            var reqBody = {
                'patient_id': this.detailItem ? parseInt(this.detailItem.patient_id) : '',
                'date': this.detailItem ? this.detailItem.accounted_date : ''
            }

            const respData = await backendAdmin.fetchCore(`/api/v1/each_accountant`, reqBody, false, false, false)

            this.accountans = respData.data.value
        }
    },
    watch: {
        // date (val) {
        //     this.dateofBirthday = this.formatDate(this.date)
        // },
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                this.getCardNumber(page, itemsPerPage)
            },
            deep: true,
        },
    },
}
</script>
<style>
.agora-video-player {
height: 100%;
width: 100%;
}
.agora-view {
    display: flex;
    flex-wrap: wrap;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.editable {
  width: 100%;
  height: 680px;
  border: thin solid rgba(143, 143, 143, 0.12);
}

.editable .span-0{ 
  font-weight: normal;  
  text-decoration: none; 
  font-style: normal;   
}

.editable .span-b{
  font-weight: bold;
}

.editable .span-u{
  text-decoration: underline;
}

.editable .span-i{
  font-style: italic;
}
</style>
